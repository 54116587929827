import React, { useContext, useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';
import { toast } from 'react-toastify';

export default function Users() {
    const { formatAddress, formatDateTime } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Set default page size
    const [pages, setPages] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [level, setlevel] = useState("");
    const [deleteUser, setDeleteUser] = useState(null); // User to delete
    const [updateUser, setupdateUser] = useState(null); // User to delete
    const [showModal, setShowModal] = useState(false); // Modal visibility
    const [showModal2, setShowModal2] = useState(false); // Modal visibility
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState("");

    const getData = async () => {
        try {
            setLoading(true);
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "admin",
                submethod: "getalluser",
                key: process.env.REACT_APP_KEY,
                search: search,
                page: currentPage,
                pageSize: pageSize,
            });
            setLoading(false);
            if (response.data.error) {
                setData([]);
                setPages(1); // Reset pages in case of error
                return;
            }
            console.log('response.data.data', response.data.data)
            setData(response.data.data);
            setPages(Math.ceil(response.data.total / pageSize));
        } catch (err) {
            setLoading(false);
            setShowError("Error fetching user data.");
        }
    };

    useEffect(() => {
        if (search) {
            setCurrentPage(1); // Reset to first page when searching
        }
        getData();
    }, [search, currentPage, pageSize]); // Re-run getData whenever these change

    const handleDelete = async (username) => {
        console.log('address', username)
        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "admin",
                submethod: "deleteuser",
                key: process.env.REACT_APP_KEY,
                username,
            });

            if (response.data.msg) {
                setMsg("User deleted successfully.");
                toast("User deleted successfully.");
                getData();
            } else {
                setShowError(response.data.error || "Error deleting user.");
            }
        } catch (err) {
            setShowError("Network error. Please try again later.");
        }
    };
    const handleUpdate = async (updateUser, level) => {
        
        if(level > 21 )
        {
            toast.error("21 Max Level updated !");
            return;
        }
        try {
            // console.log('address level', updateUser, level)
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "admin",
                submethod: "updatelevel",
                key: process.env.REACT_APP_KEY,
                username: updateUser,
                level: level
            });
            
            if (response.data.msg) {
                setMsg("User Level Update Successfully.");
                toast("User level updated successfully.");
                getData();
            } else {
                setShowError(response.data.error || "Error deleting user.");
                toast.error("21 Max Level updated !");
            }
        } catch (err) {
            setShowError("Network error. Please try again later.");
        }
    };

    const confirmUpate = () => {
        handleUpdate(updateUser, level);
        console.log("level", level);

        setShowModal2(false);
        setupdateUser(null);

    };
    const confirmDelete = () => {
        handleDelete(deleteUser);
        setShowModal(false);
        setDeleteUser(null);

    };

    return (
        <main className="page-wrapper compact-wrapper" id="pageWrapper">
            <Header />
            <div className="page-body-wrapper">
                <Sidebar />
                <div className="page-body">
                    <div className="container-fluid">
                        <div className="row page-title">
                            <div className="col-sm-6">
                                <h3>Users</h3>
                            </div>
                        </div>
                    </div>

                    {/* Search Section */}
                    <div className="form-head d-flex align-items-center">
                        <div className="input-group search-area d-inline-flex m-3" style={{ width: "25%" }}>
                            <input
                                type="text"
                                className="form-control form-control-rev pe-0"
                                placeholder="Search here"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="input-group-text input-group-text-rev"
                                    onClick={() => setSearch("")}
                                >
                                    {search ? (
                                        <img
                                            src="./assets/images/close.png"
                                            alt=""
                                            style={{ height: "30px" }}
                                        />
                                    ) : (
                                        <i className="flaticon-381-search-2" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Table Section */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="table-responsive signal-table p-3">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Address</th>
                                                    <th>User Name</th>
                                                    <th>Ref. User Name</th>
                                                    <th>Staking</th>
                                                    <th>Total Team</th>
                                                    <th>Directs</th>
                                                    <th>Rank</th>
                                                    <th>Withdrawal($)</th>
                                                    <th>Withdrawal</th>
                                                    <th>Date</th>
                                                    <th>Report</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length > 0 ? (
                                                    data.map((ele, i) => {
                                                        const index = currentPage * pageSize - pageSize + (i + 1);
                                                        return (
                                                            <tr key={ele.username}>
                                                                <td>{index}</td>
                                                                <td>
                                                                    <a
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.address}`}
                                                                        rel="noreferrer"
                                                                    >
                                                                        {formatAddress(ele.address)}
                                                                    </a>
                                                                    {ele.address ? <Copy data={ele.address} /> : "-"}
                                                                </td>
                                                                <td>{ele.username || "-"}</td>
                                                                <td>{ele.ref_username || "-"}</td>
                                                                <td>{ele.staking || "-"}</td>
                                                                <td>{ele.userlevel || "0"}</td>
                                                                <td>{ele.directs || "0"}</td>
                                                                <td>{ele.rank || "0"}</td>
                                                                <td>{ele.Withdrawal_d || "0"}</td>
                                                                <td>{ele && ele.Withdrawl_t ? parseFloat(ele.Withdrawl_t).toFixed(2) : "0"}</td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center'>
                                                                        {formatDateTime(ele.date)}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Link
                                                                        to={`/userreport/${ele.username}`}
                                                                        className="edit-appointment"
                                                                    >
                                                                        <i className="fa fa-book fs-18" />
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {ele.stake_d === 0 ? (
                                                                        <button
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() => {
                                                                                setShowModal(true);
                                                                                setDeleteUser(ele.username);
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </button>

                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-danger btn-sm"
                                                                        onClick={() => {
                                                                            setShowModal2(true);
                                                                            setupdateUser(ele.username);
                                                                            setlevel(ele.open_level)
                                                                        }}
                                                                    >
                                                                        Open Level
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="12" className="text-center">
                                                            {loading ? "Loading..." : "No data found."}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>

                                        </table>
                                    </div>

                                    {/* Pagination Section */}
                                    <div className="d-flex justify-content-center py-4">
                                        <Pagination
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            pages={pages}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {showModal && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="close" onClick={() => setShowModal(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this user?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => confirmDelete()}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModal2 && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Open Level</h5>
                                <button type="button" className="close" onClick={() => setShowModal(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Open Level: {level}</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="0"
                                        value={level}
                                        onChange={(e) => {
                                            setlevel(e.target.value); // Update the level state with the new value
                                            console.log('New level:', e.target.value); // Log the new value for debugging
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal2(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => confirmUpate()}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
}
